<template>
  <div class="container-fluid">
    <page-tabs page="events" />

    <template v-if="portal && portal.link">
      <div class="row mb-4">
        <div class="col-12">
          <a :href="'https://'+portal.link+'.'+portal.domain+'/waitlists'" class="btn btn-outline-primary" target="_blank"><i class="fas fa-external-link-alt me-2"></i>Open Portal</a>
        </div>
      </div>
    </template>
    <!-- Stats Cards Row -->
    <div class="row g-3 mb-4">
      <div class="col-md-4">
        <div class="card h-100 border-0 shadow-sm">
          <div class="card-body text-center">
            <h3 class="mb-0">{{ events.length }}</h3>
            <p class="text-muted mb-0">SCHEDULED EVENTS</p>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card h-100 border-0 shadow-sm">
          <div class="card-body text-center">
              <h3 class="mb-0">{{ checkins.length }}</h3>
              <p class="text-muted mb-0">CURRENT CHECK-INS</p>
            </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card h-100 border-0 shadow-sm">
          <div class="card-body text-center">
            <h3 class="mb-0">{{ avgWaitTime || '15 min' }}</h3>
            <p class="text-muted mb-0">AVG. WAIT TIME</p>
          </div>
        </div>
      </div>
    </div>

    <template v-if="currentLocation && currentLocation.uuid">
      <div class="row mb-4">
        <div class="col-12">
          <button class="btn btn-outline-primary" @click="closeLocation">Close {{ currentLocation.name }}</button>
        </div>
      </div>
    </template>

    <template v-else>
      <!-- Location Selection -->
      <div class="d-flex justify-content-between align-items-center mb-4" v-if="locations && locations.length">
        <template v-if="currentLocation && currentLocation.uuid">
          <button class="btn btn-outline-primary" @click="closeLocation">
            <i class="fas fa-times me-2"></i>
            Close {{ currentLocation.name }}
          </button>
        </template>
        <template v-else>
          <div class="dropdown">
            <button 
              class="btn btn-outline-primary dropdown-toggle" 
              type="button" 
              data-bs-toggle="dropdown" 
              aria-expanded="false"
            >
              <i class="fas fa-map-marker-alt me-2"></i>
              Choose Location
            </button>
            <ul class="dropdown-menu">
              <li v-for="location in locations" :key="location.uuid">
                <button 
                  class="dropdown-item" 
                  type="button"
                  @click="chooseLocation(location)"
                >
                  {{ location.name }}
                </button>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </template>

    <div class="row g-4">
      <!-- Scheduled Events List -->
      <div class="col-md-6">
        <div class="card border-0 shadow-sm">
          <div class="card-header bg-white border-0 py-3">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <i class="fas fa-calendar-alt me-2 text-primary"></i>
                Scheduled Events
              </h5>
              <button class="btn btn-sm btn-outline-primary" @click="refreshEvents">
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="list-group list-group-flush">
              <template v-if="events && events.length">
                <div v-for="event in events" :key="event.uuid" class="list-group-item">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <p class="fw-bold mb-1">{{ event.time | time }} - {{ event.case_number }}</p>
                      <p class="text-muted mb-0">{{ event.status }}</p>
                      <template v-if="event.event_reference">
                        <p class="text-muted mb-0">Ref: {{ event.event_reference }}</p>
                      </template>
                      <template v-if="event.case_number">
                        <p class="text-muted mb-0">Case: {{ event.case_number }}</p>
                      </template>
                      <template v-if="event.description">
                        <p class="text-muted mb-0">{{ event.description }}</p>
                      </template>
                      <template v-if="event.client">
                        <client-button :uuid="event.client" />
                      </template>
                    </div>

                    <div class="btn-group">
                      <a :href="'/events/'+event.uuid" class="btn btn-outline-primary btn-sm" target="_blank">
                        <i class="fas fa-eye me-1"></i> View Event
                      </a>

                    <template v-if="portal && portal.link && event.url">
                      <a :href="'https://'+portal.link+'.'+portal.domain+'/e/'+event.url" class="btn btn-outline-primary btn-sm" target="_blank">
                        <i class="fas fa-external-link-alt me-1"></i> View Portal
                      </a>
                    </template>

                    <button class="btn btn-success btn-sm" @click="checkinEvent(event.uuid)" :disabled="processing">
                        <i class="fas fa-check me-1"></i> Check In
                      </button>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else class="list-group-item text-center py-4">
                <p class="text-muted mb-0">No scheduled events</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Current Check-ins List -->
      <div class="col-md-6">
        <div class="card border-0 shadow-sm">
          <div class="card-header bg-white border-0 py-3">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <i class="fas fa-clipboard-check me-2 text-success"></i>
                Current Check-ins
              </h5>
              <button class="btn btn-sm btn-outline-success" @click="refreshCheckins">
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="list-group list-group-flush">
              <template v-if="checkins && checkins.length">
                <div v-for="checkin in checkins" :key="checkin.uuid" class="list-group-item">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <p class="fw-bold mb-1">{{ checkin.created_at | dateTimezone | timeago }}</p>
                      <p class="text-muted mb-0">{{ checkin.name }}</p>
                      <span :class="getStatusBadgeClass(checkin.status)" class="badge rounded-pill">
                        {{ checkin.status }}
                      </span>
                    </div>

                    <template v-if="checkin.client">
                      <client-button :uuid="checkin.client" />
                    </template>

                    <a :href="'/checkins/'+checkin.uuid" class="btn btn-outline-primary btn-sm" target="_blank">View Check-in</a>
                  </div>
                </div>
              </template>
              <div v-else class="list-group-item text-center py-4">
                <p class="text-muted mb-0">No current check-ins</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {
  mixins: [queries],
  
  data() {
    return {
      events: [],
      checkins: [],
      processing: false,
      avgWaitTime: '15 min',
      qrCodeUrl: null,
      portal: null,
      locations: [],
      currentLocation: null
    }
  },

  created() {
    this.getEvents();
    this.getCheckins();
    this.getPortal();
    this.getLocations();
  },

  mounted() {

  var self = this;

  this.$eventBus.$on('checkins', function(data) {

      console.log(data);
      
      if(!data.uuid) {
        return;
      }

      self.pushRecord(data);

  });

  },

  methods: {

    getEvents() {
      this.processing = true;

      var queries = {
        limit: 100,
        fields: 'uuid,time,status,event_reference,case_number,description,client,url',
        slim: true,
        // status: 'scheduled',
        not_appeared: 1,
        date: this.getCurrentDate()
      }

      if(this.currentLocation && this.currentLocation.uuid) {
        queries.location = this.currentLocation.uuid;
      }

      this.$http.get(this.buildQueries('/events', queries))
        .then(response => {
          if (response && response.data) {
            this.events = response.data;
            this.processing = false;
          }
        })
    },

    getCheckins() {
      this.processing = true;

      var queries = {
        limit: 100,
        date: this.getCurrentDate()
      }

      if(this.currentLocation && this.currentLocation.uuid) {
        queries.location = this.currentLocation.uuid;
      }

      this.$http.get(this.buildQueries('/checkins', queries))
        .then(response => {
          if (response && response.data) {
            this.checkins = response.data;
            this.processing = false;
          }
        })
    },

    getCurrentDate() {
      return new Date().toISOString().split('T')[0]
    },

    checkinEvent(uuid) {
      this.processing = true;
      this.$http.post('/checkins', { event: uuid, status: 'checked-in' })
        .then(response => {
          if (response && response.data) {
            this.$eventBus.$emit('show-alert', {
              text: 'Check-in successful',
              type: 'success'
            });

            //rm event from events array
            this.events = this.events.filter(event => event.uuid !== uuid);

            this.processing = false;
          }
        })
    },

    pushRecord(data) {

      if(!data.uuid) {
        return;
      }

      if(!this.checkins || this.checkins.length === 0) {
        this.checkins = [];
      }

      //check if the checkin is already in the array then update it if so
      if(this.checkins.find(checkin => checkin.uuid === data.uuid)) {
        this.checkins = this.checkins.map(checkin => checkin.uuid === data.uuid ? data : checkin);
      } else {
          this.checkins.push(data);
      }

    },

    getStatusBadgeClass(status) {
      switch (status) {
        case 'checked-in':
          return 'bg-success'
        case 'waiting':
          return 'bg-warning'
        case 'in-progress':
          return 'bg-primary'
        default:
          return 'bg-secondary'
      }
    },

    getPortal() {
      this.$http.get('/portals?limit=1&sort=default&sortDirection=desc&slim=true&fields=uuid,name,link,domain&domain=ecase.io')
        .then(response => {
          if (response && response.data && response.data[0]) {
            this.portal = response.data[0];
          }
        })
    },

    getLocations() {
      this.$http.get('/locations?slim=true&fields=uuid,name&sort=default&sortDirection=desc')
        .then(response => {
          if (response && response.data) {
            this.locations = response.data;
          }
        })
    },

    chooseLocation(location) {
      if(this.currentLocation && this.currentLocation.uuid === location.uuid) {
        this.currentLocation = null;
      } else {
        this.currentLocation = location;
      }

      this.refreshEvents();
      this.refreshCheckins();
    },

    closeLocation() {
      this.currentLocation = null;
      this.refreshEvents();
      this.refreshCheckins();
    },

    refreshEvents() {
      this.events = [];
      this.getEvents();
    },

    refreshCheckins() {
      this.checkins = [];
      this.getCheckins();
    }
  }
}
</script>